import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TelevisionComponent } from './television.component';
import { AuthGuard } from '../guards/auth.guard';

const routes: Routes = [
  {
    path: 'television',
    component: TelevisionComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    data: {role: 'oss_client_iptv'},
    children: [
      // {
      //   path: '',
      //   redirectTo: 'endirecto',
      //   pathMatch: 'full'
      // },
      {
        path: '',
        loadChildren: () => import('./web-tv/web-tv.module').then( m => m.WebTvPageModule)
      },
      // {
      //   path: 'endirecto',
      //   loadChildren: () => import('./channels-list/channels-list.module').then( m => m.ChannelsListPageModule)
      // },
      // {
      //   path: 'grabaciones',
      //   loadChildren: () => import('./ondemand/ondemand.module').then( m => m.OndemandPageModule)
      // },
      // {
      //   path: 'directo/:listId/:channelId',
      //   loadChildren: () => import('./channel-detail/channel-detail.module').then( m => m.ChannelDetailPageModule)
      // }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TelevisionRoutingModule { }
