import { NgModule } from '@angular/core';
import { SkeletonListComponent } from './skeleton-list/skeleton-list.component';

import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { ToolbarComponent } from './toolbar/toolbar.component';
import { ScrollVanishDirective } from './directives/scroll-vanish.directive';
import { FileUploadModule } from 'ng2-file-upload';
import { RouterModule } from '@angular/router';
import { ShowHidePasswordComponent } from './show-hide-password/show-hide-password.component';
import { SecurePipe } from './pipes/secure-pipe';
import { TranslateModule } from '@ngx-translate/core';
import { SafePipeModule } from 'safe-pipe';
import { LangSelectorComponent } from './lang-selector/lang-selector.component';

@NgModule({
  declarations: [SkeletonListComponent, ToolbarComponent, ScrollVanishDirective, ShowHidePasswordComponent, LangSelectorComponent, SecurePipe],
  imports: [CommonModule, IonicModule, RouterModule,
    FileUploadModule, TranslateModule, SafePipeModule],
  exports: [SkeletonListComponent, ToolbarComponent, ScrollVanishDirective, ShowHidePasswordComponent, LangSelectorComponent, SecurePipe, TranslateModule, SafePipeModule]
})
export class ComponentsModule { }
