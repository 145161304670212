// yyyy-mm-dd
export const dateToString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const d = new Date(date.getTime() - (offset*60000));
  return d.toISOString().split('T')[0];
};

// hh:mm
export const timeToString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const d = new Date(date.getTime() - (offset*60000));
  //console.log(d.toISOString().split('T')[1].slice(0,5));
  return d.toISOString().split('T')[1].slice(0,5);
};

// hh:mm:ss
export const timeToString8 = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const d = new Date(date.getTime() - (offset*60000));
  //console.log(d.toISOString().split('T')[1].slice(0,5));
  return d.toISOString().split('T')[1].slice(0,8);
};

// yyyy-mm-dd hh:mm:ss
export const datetimeToString = (date: Date) => {
  const offset = date.getTimezoneOffset();
  const d = new Date(date.getTime() - (offset*60000));
  //console.log(d.toISOString().split('T')[1].slice(0,5));
  const s = d.toISOString().split('T');
  return s[0] + ' ' + s[1].slice(0,8);
};

export const download = (response: Blob, filename?: string) => {
  //console.log(response, typeof response, filename);
  if (response) {
    const dataType = response.type;
    const binaryData = [];
    binaryData.push(response);
    const downloadLink = document.createElement('a');
    downloadLink.href = window.URL.createObjectURL(new Blob(binaryData, {type: dataType, }));
    if (filename) {
        downloadLink.setAttribute('download', filename);
    }
    document.body.appendChild(downloadLink);
    downloadLink.click();
  }
};

export const roundDate = (d: Date, minutes: number) => {
  var millis = d.getTime();
  const millisInMins = 60000 * minutes;
  millis = Math.round(millis/millisInMins) * millisInMins;

  return new Date(millis);
};

export const truncateDate = (d: Date, minutes: number) => {
  var millis = d.getTime();
  const millisInMins = 60000 * minutes;
  millis = Math.floor(millis/millisInMins) * millisInMins;

  return new Date(millis);
};
