import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { firstValueFrom, Subject, throwError } from 'rxjs';
import { catchError, map, shareReplay } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { Egp, GetChannels, PortalChannel, PortalChannelList } from '../models/television';
import { AuthService } from './auth.service';
import { ScreenService } from './screen.service';
import { roundDate, truncateDate } from './utils';

@Injectable({
  providedIn: 'root'
})
export class TelevisionService {

  public arrayChannelsLists$ = new Subject<PortalChannelList[]>();
  public arrayChannelsLists: PortalChannelList[] = [];
  public actualChannel = 0;
  public actualList = 0;

  public readonly PX_PER_MINUTE = 3;

  // hora actual redondeada
  public windowActualStart: Date;
  // hora desde la que se pinta la tabla de info
  public windowStart: Date;
  // ancho de la ventana
  public width: number;
  // hora hasta la que se pinta la tabla de info. Depende del ancho de la ventana
  public windowEnd: Date;

  // hora hasta la que se pinta la tabla de info. Depende del ancho de la ventana
  public getWindowEnd(width: number) : Date {
    const wStartInMillis = this.windowStart.getTime();
    const wEndInMillis = wStartInMillis + (60000 * width / this.PX_PER_MINUTE);
    return roundDate(new Date(wEndInMillis), 30);
  }

  // hora de la ventana anterior (al pulsar el prev)
  public getWindowPrev() : Date {
    const wStartInMillis = this.windowStart.getTime();
    const wEndInMillis = this.windowEnd.getTime();
    const diff = wEndInMillis - wStartInMillis;
    return new Date(wStartInMillis - diff);
  }

  public windowNow() {
    this.windowActualStart = truncateDate(new Date(), 30)
    this.calculateEgps2show(this.windowActualStart, this.width);
  }

  public windowNext() {
    this.calculateEgps2show(this.windowEnd, this.width);
  }

  public windowPrev() {
    this.calculateEgps2show(this.getWindowPrev(), this.width);
  }

  // egps que hay que pintar en la tabla de info, para un canal concreto
  public getEgps(listIndex: number, channelIndex: number): Egp[] {

    return this.getChannel(listIndex, channelIndex).egp2show;
  }

  calculateEgps2show(windowStart: Date, width: number) {
    // if (this.width != width || this.windowStart != windowStart) {
      this.width = width;
      this.windowStart = windowStart;
      //console.log('calculateEgps2show width: ' + this.width);
      this.windowEnd = this.getWindowEnd(this.width);
      for (var list of this.arrayChannelsLists) {
        for (var channel of list.portalChannels) {
          channel.egp2show = channel.epg.filter(egp => egp.end > this.windowStart && egp.start < this.windowEnd);
        }
      }
    // }
  }

  constructor(
    private http: HttpClient,
    private auth: AuthService,
    private screen: ScreenService
  ) {
    this.windowActualStart = truncateDate(new Date(), 30);
    this.windowStart = this.windowActualStart;
    this.loadChannels();
  }

  public async loadChannels() {
    let httpParams = new HttpParams();
    httpParams = httpParams.append("sessionId", this.auth._sessionId);
    httpParams = httpParams.append("timestamp", Math.floor(new Date().getTime()/1000.0));

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

     this.arrayChannelsLists = await firstValueFrom(this.http.get<GetChannels>(environment.SERVER_URL + '/frontend/json.portal', httpOptions).pipe(
        catchError(error => {
          console.error(error);
          return throwError(() => error);
        }),
        map(res => {
          return this.cleanAndSortEgps(res.portalChannelsLists);
        }),
        shareReplay()));

    this.arrayChannelsLists$.next(this.arrayChannelsLists);

    // this.arrayChannelsLists$ = this.http.get<GetChannels>(environment.SERVER_URL + '/frontend/json.portal', httpOptions).pipe(
    //   map(res => {
    //     return this.cleanAndSortEgps(res.portalChannelsLists);
    //   }),
    //   shareReplay());
    // this.arrayChannelsLists$.subscribe(res => {
    //         this.arrayChannelsLists = res;
    //       },
    //       error => {
    //         console.log(error);
    //       }
    //     );
  }

  cleanAndSortEgps(pcl: PortalChannelList[]): PortalChannelList[] {
    for (var list of pcl) {
      for (var channel of list.portalChannels) {
        channel.epg.forEach(egp => {
          egp.start = new Date(egp.start);
          egp.end = new Date(egp.end);
        });
        channel.epg.sort((a,b) => a.start.getTime() - b.start.getTime());
      }
    }

    return pcl;
  }

  loadLogo(logo: string): string {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sessionId', this.auth._sessionId);
    //httpParams = httpParams.append("timestamp", Math.floor(new Date().getTime()/1000.0));

    return environment.SERVER_URL + '/frontend/' + logo +'?' + httpParams.toString();
  }

  thumbnail(listId: number, channelId: number, dark: boolean): string {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sessionId', this.auth._sessionId);
    const channel = this.getChannel(listId, channelId);
    var file: string;
    if (channel == undefined) {
      return undefined;
    }
    if (dark) {
      file = channel.thumbnailDark? channel.thumbnailDark: channel.thumbnail;
    } else {
      file = channel.thumbnailLight? channel.thumbnailLight: channel.thumbnail;
    }
    return environment.SERVER_URL + '/frontend/' + file +'?' + httpParams.toString();
  }

  logo(listId: number, channelId: number, dark: boolean): string {
    let httpParams = new HttpParams();
    httpParams = httpParams.append('sessionId', this.auth._sessionId);
    const channel = this.getChannel(listId, channelId);
    if (channel == undefined) {
      return undefined;
    }
    var file: string;
    if (dark) {
      file = channel.logoDark? channel.logoDark: channel.logo;
    } else {
      file = channel.logoLight? channel.logoLight: channel.logo;
    }
    return environment.SERVER_URL + '/frontend/' + file +'?' + httpParams.toString();
  }

  public getChannel(listIndex: number, channelIndex: number): PortalChannel | undefined {
    return this.arrayChannelsLists? this.arrayChannelsLists[listIndex]?.portalChannels[channelIndex]: undefined;
  }

  public getActualChannel(): PortalChannel | undefined {
    return this.getChannel(this.actualList, this.actualChannel);
  }

  public getActualEgp(): Egp | undefined {
    const now = new Date();
    return this.getChannel(this.actualList, this.actualChannel)?.epg?.find(egp => egp.end > now && egp.start < now);
  }

  public hasNext() {
    return this.arrayChannelsLists && this.actualChannel < (this.arrayChannelsLists[this.actualList].portalChannels.length - 1);
  }

  public hasPrev() {
    return this.arrayChannelsLists && this.actualChannel > 0;
  }

}
