import { Component, OnInit } from '@angular/core';

import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { APP_PAGES } from './models/auth-models';
import { AuthService } from './services/auth.service';
import { ScreenService } from './services/screen.service';
import { LANGS, SettingsService } from './services/settings.service';

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent implements OnInit {

  public appPages = APP_PAGES;
  public appName = environment.APP_NAME;
  showTasksMenu = true;

  constructor(
    public size: ScreenService,
    public auth: AuthService,
    public translate: TranslateService,
    public settingsService: SettingsService
  ) {
    translate.addLangs(LANGS);
    translate.setDefaultLang(this.translate.getLangs()[0]);
    translate.use(this.translate.getLangs()[0]);
    auth.getStoredLang().then(lang => {
      if (lang) {
        translate.use(lang);
      } else {

      }
    });
    this.settingsService.settings$.subscribe(settings => {
      if (settings) {
        this.translate.setDefaultLang(settings.language);
        document.body.classList.toggle('dark', settings.darkMode);
      }
    });
  }

  ngOnInit() {
    this.auth.parameters.subscribe(async () => {
      this.showTasksMenu = this.auth.getPermissionParamValue('EXTRANET_TICKETS_AVAILABLE', true);
    });
  }

  logout() {
    this.auth.logout();
  }

  toggleMenu() {
    this.size.toggleMenu();
  }

}
