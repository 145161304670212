import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { ProductsComponent } from './products.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: 'products',
    component: ProductsComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./products-list/products-list.module').then( m => m.ProductsListPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./product-detail/product-detail.module').then( m => m.ProductDetailPageModule)
      }
    ]
  }]
;

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class ProductsRoutingModule { }
