import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { TelevisionRoutingModule } from './television-routing.module';
import { TelevisionComponent } from './television.component';
import { IonicModule } from '@ionic/angular';
import { ComponentsModule } from '../components/components.module';
import { ReactiveFormsModule } from '@angular/forms';


@NgModule({
  declarations: [TelevisionComponent],
  imports: [
    CommonModule,
    IonicModule,
    ComponentsModule,
    ReactiveFormsModule,
    TelevisionRoutingModule
  ]
})
export class TelevisionModule { }
