import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'app-skeleton-list',
  templateUrl: './skeleton-list.component.html',
  styleUrls: ['./skeleton-list.component.scss'],
})
export class SkeletonListComponent implements OnInit {

  private privIcon: string = undefined;
  private privLength = 15;
  private privMinWidth = 10;
  private privMaxWidth = 15;
  private privSkeletonArray: number[];

  constructor() {

  }

  public get skeletonArray() {
    if(!this.privSkeletonArray) {
      this.privSkeletonArray = this.randomArray(this.privLength, this.privMinWidth, this.privMaxWidth);
    }
    return this.privSkeletonArray;
  }

  get icon(): string { return this.privIcon; }
  @Input()
  set icon(icon: string) {
    this.privIcon = (icon && icon.trim()) || undefined;
  }

  @Input()
  set length(length: number) { this.privLength = length; }

  @Input()
  set minWidth(minWidth: number) { this.privMinWidth = minWidth; }

  @Input()
  set maxWidth(maxWidth: number) { this.privMaxWidth = maxWidth; }


  randomArray(length = 15, minValue = 1, maxValue = 10): number[] {
    const ret: number[] = [];
    for (let i  = length; length; length--) {
      ret.push(Math.floor(Math.random()*(maxValue - minValue) + minValue));
    }
    return ret;
  }

  ngOnInit() {}

}
