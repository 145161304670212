import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { TasksComponent } from './tasks.component';
import { AuthGuard } from '../guards/auth.guard';


const routes: Routes = [
  {
    path: 'tasks',
    component: TasksComponent,
    canActivate: [AuthGuard],
    canActivateChild: [AuthGuard],
    children: [
      {
        path: '',
        loadChildren: () => import('./tasks-list/tasks-list.module').then( m => m.TasksListPageModule)
      },
      {
        path: ':id',
        loadChildren: () => import('./task-detail/task-detail.module').then( m => m.TaskDetailPageModule)
      }
    ]
  },
  {
    path: 'new-task',
    canActivate: [AuthGuard],
    loadChildren: () => import('./new-task/new-task.module').then( m => m.NewTaskPageModule)
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class TasksRoutingModule { }
