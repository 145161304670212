import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, from, fromEvent } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { Platform } from '@ionic/angular';
import { Storage } from '@ionic/storage-angular';

export interface Settings {
  language: string;
  darkMode: boolean;
}

export const SETTINGS_KEY = 'es.sambatel.pwa.settings';

export const LANGS_DICT = {
  es: 'Español',
  cat: 'Català',
  en: 'English',
  fr: 'Français',
  de: 'Deutsch',
  it: 'Italiano'
};

export const LANGS = ['es' ,'cat', 'en']; // , 'fr', 'de', 'it'

@Injectable({
  providedIn: 'root'
})
export class SettingsService {

  public settings$ = new BehaviorSubject<Settings>(null);
  public settings: Settings;
  public loaded$ = new BehaviorSubject(false);
  public prefersDarkChecker: MediaQueryList;

  constructor(
    private storage: Storage,
    private plt: Platform
  ) {
    this.prefersDarkChecker = window.matchMedia('(prefers-color-scheme: dark)');
    this.loadStoredSettings();
//    this.prefersDarkChecker.addEventListener('change', e => this.setSetting('darkMode', e.matches));
    fromEvent<MediaQueryListEvent>(this.prefersDarkChecker, 'change').subscribe(e => this.setSetting('darkMode', e.matches));
  }

  loadStoredSettings() {
    const platformObs = from(this.plt.ready());
    platformObs.pipe(
      switchMap(() => from(this.storage.get(SETTINGS_KEY)))
    ).subscribe(settings => {
      if (settings) {
        this.settings = settings;
      } else {
        this.settings = {
          language: LANGS[0],
          darkMode: this.prefersDarkChecker.matches
        };
      }
      this.settings$.next(this.settings);
      this.loaded$.next(true);
    });
  }

  saveSettings(): Observable<any> {
    const storageObs = from(this.storage.set(SETTINGS_KEY, this.settings));
    this.settings$.next(Object.assign({},this.settings));
    return storageObs;
  }

  public setSetting(key: string, value: any) {
    this.settings[key] = value;
    this.saveSettings();
  }
}
