/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { ScreenService } from '../../services/screen.service';

@Component({
  selector: 'app-toolbar',
  templateUrl: './toolbar.component.html',
  styleUrls: ['./toolbar.component.scss'],
})
export class ToolbarComponent {

  @Output() iconClicked = new EventEmitter<number>();

  private privAppScrollVanish;
  private privIcons: Array<string> = [];
  private privTitle: string = undefined;
  private privShowBackButton = true;
  private privIconsColor: string = undefined;

  constructor(
    public size: ScreenService
  ) {
    this.iconsColor = undefined;
  }

  get appScrollVanish(): any { return this.privAppScrollVanish; }
  @Input()
  set appScrollVanish(appScrollVanish: any) {
    this.privAppScrollVanish = appScrollVanish;
  }

  get icons(): Array<string> { return this.privIcons; }
  @Input()
  set icons(icons: Array<string>) {
    this.privIcons = icons || [];
  }

  get iconsColor(): string { return this.privIconsColor; }
  @Input()
  set iconsColor(ic: string) {
    this.privIconsColor = ic || 'var(--ion-color-primary)';
  }

  get title(): string { return this.privTitle; }
  @Input()
  set title(title: string) {
    this.privTitle = (title && title.trim()) || undefined;
  }

  get showBackButton(): boolean { return this.privShowBackButton; }
  @Input()
  set showBackButton(showBackButton: boolean) {
    this.privShowBackButton = showBackButton;
  }

  _iconClicked(index: number) {
    this.iconClicked.emit(index);
  }

}
