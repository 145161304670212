import { Injectable, OnDestroy } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable } from 'rxjs';
import { tap } from 'rxjs/operators';
import { shareReplay } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { HttpUnsecuredErrorHandler, HandleError } from './http-unsecured-error-handle.service';
import { LoadProfileRequest, LoadProfileResponse, LoginRequest, LoginResponse, LogoutResponse, Parameter, RegisterRequest, ResendActivateRequest } from '../models/dto/authentication';
import { Router } from '@angular/router';
import { Profile, SaveProfileRequest, SaveProfileResponse } from '../models/profile';
import { StorageService } from './storage.service';
import { TranslateService } from '@ngx-translate/core';

export const LANG_KEY = 'es.krama.pwa.telco.LANG_KEY';
export const SESSION_KEY = 'es.krama.pwa.telco.SESSION_KEY';
export const PROFILE_KEY = 'es.krama.pwa.telco.PROFILE_KEY';
export const CREDENTIALS_KEY = 'es.krama.pwa.telco.CREDENTIALS_KEY';
export const KEEP_ALIVE_INTERVAL = 600000;


@Injectable({
  providedIn: 'root'
})
export class AuthService implements OnDestroy {

//  public _sessionId: string = '1aae1ad8:183c239225f:-64da';
  public _sessionId: string = null;
  public _profile: Profile = null;
  public _storedCredentials : LoginRequest = null;
  public _storedLang : string = null;

  public parameters: BehaviorSubject<Parameter[]> = new BehaviorSubject([]);
  public billingParameters: BehaviorSubject<Parameter[]> = new BehaviorSubject([]);
  public redirectUrl: string;
  private handleError: HandleError;
  private time: any; //NodeJS.Timeout | undefined;

  constructor(
      private storage: StorageService,
      private http: HttpClient,
      private router: Router,
      private translate: TranslateService,
      httpErrorHandler: HttpUnsecuredErrorHandler) {
    this.handleError = httpErrorHandler.createHandleError('AuthService');
    this.getSessionId();
    this.getProfile();
    this.keepAliveLoop();
    this.storage.get('paramsExtranet').then((parameters: Parameter[]) => {
      this.parameters.next(parameters);
    });
    this.storage.get('paramsBilling').then((parameters: Parameter[]) => {
      this.billingParameters.next(parameters);
    });
  }

  ngOnDestroy(): void {
    if (this.time) {
      clearInterval(this.time);
    }
    this.time = undefined;
  }

  keepAliveLoop() {
    if (this.time) {
      this.keepAlive().subscribe();
    }
    this.time = setTimeout(() =>{this.keepAliveLoop();}, KEEP_ALIVE_INTERVAL);
  }

  public useLang(lang: string) {
    this._storedLang = lang;
    this.translate.use(lang);
    this.storage.set(LANG_KEY, lang).then(
      res => {
        console.log('Stored lang', res);
        this.ping();
      }
    );
  }

  public async getStoredLang(): Promise<string> {
    if (this._storedLang == null) {
      this._storedLang = await this.storage.get(LANG_KEY);
    }
    return this._storedLang;
  }

  public getLocale(): string {
    var ret = this._storedLang || 'es';
    ret += '_';

    if (this._storedLang === 'en') {
      ret += 'GB';
    } else {
      ret += 'ES';
    }

    return ret;
  }

  public async getStoredCredentials(): Promise<LoginRequest> {
    if (this._storedCredentials == null) {
      this._storedCredentials = await this.storage.get(CREDENTIALS_KEY);
    }
    return this._storedCredentials;
  }

  public setStoredCredentials(storedCredentials: LoginRequest) {

    this._storedCredentials = storedCredentials;
    if (storedCredentials != null) {
      this.storage.set(CREDENTIALS_KEY, storedCredentials);
    } else {
      this.storage.remove(CREDENTIALS_KEY);
    }
  }

  public async getSessionId() {
    // await this.setSessionId('1aae1ad8:183c239225f:-64da');
    // this._sessionId = null; //'1aae1ad8:183c239225f:-64da';

    if (this._sessionId == null) {
      this._sessionId = await this.storage.get(SESSION_KEY);
    }
    return this._sessionId;
  }

  public async getProfile(): Promise<Profile> {
    if (this._profile == null) {
      this._profile = await this.storage.get(PROFILE_KEY);
    }
    return this._profile;
  }


  setSessionId(sessionId: string) {
    // this._sessionId = '1aae1ad8:183c239225f:-64da';
    // this.storage.set(SESSION_KEY, this._sessionId);
    // return;

    this._sessionId = sessionId;
    if (sessionId != null) {
      this.storage.set(SESSION_KEY, sessionId);
    } else {
      this.storage.remove(SESSION_KEY);
    }
  }

  setProfile(profile: Profile) {
    this._profile = profile;
    if (profile != null) {
      this.storage.set(PROFILE_KEY, profile);
    } else {
      this.storage.remove(PROFILE_KEY);
    }
  }

  ping() : void {
    // if (!this._sessionId) {
    //   console.log('PING', 'Invalid sessionId')
    //   return;
    // }
    const httpParams = new HttpParams({
      fromObject: {
        method: 'ping',
        locale: this.getLocale(),
        request: JSON.stringify({sessionId: this._sessionId})
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    this.http.post<string>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions)
      .pipe(
        shareReplay(),
      ).subscribe(res => console.log('PING', res));
  }

  login(credentials: LoginRequest): Observable<LoginResponse> {
    //credentials.locale = this.getLocale();
    const httpParams = new HttpParams({
      fromObject: {
        method: 'login',
        locale: this.getLocale(),
        request: JSON.stringify(credentials)
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<LoginResponse>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions)
      .pipe(
        tap(res => {
          if (res.configuration?.parametersGroups?.length > 0) {
            for (const group of res.configuration.parametersGroups) {
              if (group.name === 'extranet') {
                this.storage.set('paramsExtranet', group.parameters);
                this.parameters.next(group.parameters);
              }
              if (group.name === 'billing') {
                this.storage.set('paramsBilling', group.parameters);
                this.billingParameters.next(group.parameters);
              }
            }
          }
          this._sessionId = res.sessionId;
          this.setSessionId(res.sessionId);
        }),
        shareReplay(),
      );
  }

  getPermissionParamValue(paramName: string, defaultValue?: any) {
    let result = defaultValue;
    const parameters = this.parameters.getValue() ?? this._profile.licenses[0].configurationProfile.parametersGroups.find(g => g.name === 'extranet')?.parameters;
    const param = parameters?.find(p => p.name === paramName);
    if (param) {
      if (param.value === 'true') {
        result = true;
      } else if (param.value === 'false') {
        result = false;
      } else {
        result = param.value;
      }
    }
    return result;
  }

  getBillingParamValue(paramName: string, defaultValue?: any) {
    let result = defaultValue;
    const parameters = this.billingParameters.getValue() ?? this._profile.licenses[0].configurationProfile.parametersGroups.find(g => g.name === 'billing')?.parameters;
    const param = parameters?.find(p => p.name === paramName);
    if (param) {
      if (param.value === 'true') {
        result = true;
      } else if (param.value === 'false') {
        result = false;
      } else {
        result = param.value;
      }
    }
    return result;
  }

  keepAlive(): Observable<LoadProfileResponse> {
    const httpParams = new HttpParams({
      fromObject: {
        method: 'keepalive',
        request: JSON.stringify({sessionId: this._sessionId})
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<LoadProfileResponse>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', httpParams, httpOptions)
      .pipe(
        tap(res => {
          this.setProfile(res.profile);
        }),
      );
  }

  loadProfile(): Observable<LoadProfileResponse> {
    const httpParams = new HttpParams({
      fromObject: {
        method: 'loadProfile',
        request: JSON.stringify({sessionId: this._sessionId})
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<LoadProfileResponse>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', httpParams, httpOptions)
      .pipe(
        tap(res => {
          this.setProfile(res.profile);
        }),
      );
  }

  async logout() {
    var profile = await this.getProfile();
    var sessionId = await this.getSessionId();
    const httpParams = new HttpParams({
      fromObject: {
        method: 'logout',
        request: JSON.stringify({sessionId: sessionId, username: profile.username, password: profile.password})
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<LogoutResponse>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions).pipe(shareReplay()).subscribe(res => {
        this.setSessionId(null);
        this.setProfile(null);
        this.router.navigate(['/login']);
    });
  }

  saveUserData(request: SaveProfileRequest) {
    const httpParams = new HttpParams({
      fromObject: {
        method: 'saveProfile',
        request: JSON.stringify(request)
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      })
    };

    return this.http.post<SaveProfileResponse>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', httpParams, httpOptions)
      .pipe(
        tap(res => {this.setProfile(res.profile)}),
        shareReplay(),
      );
  }

  register(newUser: RegisterRequest): Observable<LoginResponse> {
    const httpParams = new HttpParams({
      fromObject: {
        locale: this.getLocale(),
        method: 'register',
        request: JSON.stringify(newUser)
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<any>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions)
      .pipe(
        tap(res => {
          this.setSessionId(res.sessionId);
        }),
        shareReplay(),
      );
  }

  resendActivation(resendActivateRequest: ResendActivateRequest): Observable<LoginResponse> {
    const httpParams = new HttpParams({
      fromObject: {
        locale: this.getLocale(),
        method: 'resendActivation',
        request: JSON.stringify(resendActivateRequest)
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<any>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions)
      .pipe(
        tap(res => {
          this.setSessionId(res.sessionId);
        }),
        shareReplay(),
      );
  }

  resetPassword(resendActivateRequest: ResendActivateRequest): Observable<LoginResponse> {
    const httpParams = new HttpParams({
      fromObject: {
        locale: this.getLocale(),
        method: 'resetPassword',
        request: JSON.stringify(resendActivateRequest)
      }
    });

    const httpOptions = {
      headers: new HttpHeaders({
        'Content-Type': 'application/x-www-form-urlencoded'
      }),
      params: httpParams
    };

    return this.http.post<any>(environment.SERVER_URL + '/frontend/oss_aaa_authentication.ajax', null, httpOptions)
      .pipe(
        tap(res => {
          this.setSessionId(res.sessionId);
        }),
        shareReplay(),
      );
  }

}
