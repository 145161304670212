export interface SessionDto {
  sessionId: string
}

export interface AppPage {
  title: string;
  url: string;
  queryParams?: any;
  icon: string;
  role: string | undefined;
  permissionParam?: string;
}

export const APP_PAGES: AppPage[] = [
  {
    title: 'Inicio',
    url: '/home',
    icon: 'home',
    role: undefined,

  },
  {
    title: 'Datos',
    url: '/profile',
    icon: 'person',
    role: undefined
  },
  {
    title: 'Productos',
    url: '/products',
    icon: 'cube',
    role: undefined
  },
  {
    title: 'Facturas',
    url: '/bills',
    icon: 'document-text',
    role: undefined
  },
  {
    title: 'Tareas',
    url: '/tasks',
    icon: 'notifications',
    role: undefined,
    permissionParam: 'EXTRANET_TICKETS_AVAILABLE'
  },
  {
    title: 'Television',
    url: '/television',
    icon: 'tv',
    role: 'oss_client_iptv'
  }
];
