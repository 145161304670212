/* eslint-disable @typescript-eslint/member-ordering */
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { AuthService } from 'src/app/services/auth.service';
import { LANGS, LANGS_DICT } from 'src/app/services/settings.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'lang-selector',
  templateUrl: './lang-selector.component.html',
  styleUrls: ['./lang-selector.component.scss'],
})
export class LangSelectorComponent {

  public langs = LANGS;
  public langsDisct = LANGS_DICT;

  constructor(
    public auth: AuthService,
    public translate: TranslateService
  ) {
  }

  public triggerEvent(ev: any) {
    if (!environment.production) console.log('triggerEvent', ev);
    this.auth.useLang(ev?.detail?.value);
  }

}
