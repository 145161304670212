import { Injectable } from '@angular/core';
import { Platform } from '@ionic/angular';
import { fromEvent, Subject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class ScreenService {

  public static readonly BREAKPOINTS = {
    xs: 0   ,
    sm: 576 ,
    md: 768 ,
    lg: 992 ,
    xl: 1200
  };

  public width: number;
  public menuWidth: number;
  public contentWidth: number;
  public height: number;
  public xs = true;
  public sm: boolean;
  public md: boolean;
  public lg: boolean;
  public xl: boolean;

  public xsContent = true;
  public smContent: boolean;
  public mdContent: boolean;
  public lgContent: boolean;
  public xlContent: boolean;

  public prefersDark: MediaQueryList;

  public darkMode: boolean;
  public portrait: boolean;

  public size: string;

  public splitPane: boolean = true;
  public forceSplit = false;

  public width$ = new Subject<number>();
  public contentWidth$ = new Subject<number>();
  public menuWidth$ = new Subject<number>();
  public height$ = new Subject<number>();
  public size$ = new Subject<string>();
  public darkMode$ = new Subject<boolean>();
  public portrait$ = new Subject<boolean>();

  constructor(
    private platform: Platform
  ) {

    this.platform.ready().then(() => {
      this.prefersDark = window.matchMedia('(prefers-color-scheme: dark)');

      this.darkMode = this.prefersDark.matches;
      this.darkMode$.next(this.darkMode);
      fromEvent<MediaQueryListEvent>(this.prefersDark, 'change').subscribe(e => {
        this.darkMode = e.matches;
        this.darkMode$.next(this.darkMode);
      });

      this.calc();
      fromEvent<Event>(window, 'resize').subscribe(e => {
        this.calc();
      });
    })
  }

  public toggleMenu() {
    this.forceSplit = !this.forceSplit;
    this.splitPane = !this.splitPane;
    this.calc();
  }

  public calc() {

    this.splitPane = this.forceSplit? this.splitPane: window.innerWidth >= ScreenService.BREAKPOINTS.lg;

    var newMenuWidth = this.getSplitPaneWidth();

    if (this.width !== window.innerWidth || newMenuWidth != this.menuWidth) {

      this.width = window.innerWidth;

      this.menuWidth = this.getSplitPaneWidth();
      this.contentWidth = this.width - this.menuWidth;

      this.xs = this.width >= ScreenService.BREAKPOINTS.xs;
      this.sm = this.width >= ScreenService.BREAKPOINTS.sm;
      this.md = this.width >= ScreenService.BREAKPOINTS.md;
      this.lg = this.width >= ScreenService.BREAKPOINTS.lg;
      this.xl = this.width >= ScreenService.BREAKPOINTS.xl;

      this.xsContent = this.contentWidth >= ScreenService.BREAKPOINTS.xs;
      this.smContent = this.contentWidth >= ScreenService.BREAKPOINTS.sm;
      this.mdContent = this.contentWidth >= ScreenService.BREAKPOINTS.md;
      this.lgContent = this.contentWidth >= ScreenService.BREAKPOINTS.lg;
      this.xlContent = this.contentWidth >= ScreenService.BREAKPOINTS.xl;

      this.width$.next(this.width);
      this.contentWidth$.next(this.contentWidth);
      this.menuWidth$.next(this.menuWidth);

      const newSize = this.xl? 'xl': this.lg? 'lg': this.md? 'md': this.sm? 'sm': 'xs';

      if (this.size !== newSize) {
        this.size = newSize;
        this.size$.next(this.size);
      }
    }
    if (this.height !== window.innerHeight) {
      this.height = window.innerHeight;
      this.height$.next(this.height);
    }

    const newPortrait = this.width > this.height;
    if (this.portrait === undefined || newPortrait != this.portrait) {
      this.portrait = newPortrait;
      this.portrait$.next(this.portrait);
      //console.log('portrait', this.portrait);
      //console.log('screen.orientation', screen.orientation);

    }
  }

  public isMobile() : boolean {
    return this.platform.is('mobile');
  }

  public async lockPortrait() {
    if (this.isMobile()) {
      try {
        await document.documentElement.requestFullscreen();
        await screen.orientation.lock('portrait');
      } catch (e) {
        //console.log("CATCH Error", e);
      }
    }
  }

  public async lockLandscape() {
    if (this.isMobile()) {
        try {
        await document.documentElement.requestFullscreen();
        await screen.orientation.lock('landscape');
      } catch (e) {
        //console.log("CATCH Error", e);
      }
    }
  }

  public async setFullScreen() {
    try {
      await document.documentElement.requestFullscreen();
    } catch (e) {
      //console.log("CATCH Error", e);
    }
  }

  public unlockOrientation() {
    screen.orientation.unlock();
  }

  public getSplitPaneWidth() : number {
    if (!this.splitPane) {
      return 0;
    }

    var ret = this.width * 0.3;

    if (ret >= 250) {
      return 250;
    } else if (ret <= 150) {
      return 150;
    } else {
      return ret;
    }
  }

  private getContentWidth() {
    return this.width - this.getSplitPaneWidth();
  }
}
