import { Injectable } from '@angular/core';

import { Storage } from '@ionic/storage-angular';

@Injectable({
  providedIn: 'root'
})
export class StorageService {
  private _storage$: Promise<Storage>;

  constructor(private storage: Storage) {
    this.init();
  }

  async init() {
    // If using, define drivers here: await this.storage.defineDriver(/*...*/);
    //console.log('storage.init...');
    this._storage$ = this.storage.create();
    //console.log('storage.init finished');

  }

  // Create and expose methods that users of this service can
  // call, for example:
  public async set(key: string, value: any): Promise<any> {
    //console.log('storage.set', key, value);
    var _st = await this._storage$;
    var ret = await _st.set(key, value);
    //console.log('storage.set', key, value, ret);
    return ret;
  }

  public async get(key: string): Promise<any> {
    //console.log('storage.get', key);
    var _st = await this._storage$;
    var ret = await _st?.get(key);
    //console.log('storage.got', key, ret);
    return ret;
  }

  public async remove(key: string): Promise<any> {
    //console.log('storage.remove', key);
    var _st = await this._storage$;
    var ret = await _st?.remove(key);
    //console.log('storage.removed', key, ret);
    return ret;
  }
}
