import { Injectable } from '@angular/core';
import { HttpErrorResponse } from '@angular/common/http';

import { Observable, of } from 'rxjs';
import { AlertController } from '@ionic/angular';

/** Type of the handleError function returned by HttpErrorHandler.createHandleError */
export type HandleError =
  <T> (operation?: string, result?: T) => (error: HttpErrorResponse) => Observable<T>;

/** Handles HttpClient errors */
@Injectable({
  providedIn: 'root'
})
export class HttpUnsecuredErrorHandler {

  public connectionError = false;

  constructor(
    private alertCtrl: AlertController
    ) { }

  /** Create curried handleError function that already knows the service name */
  createHandleError = (serviceName = '') => <T>
    (operation = 'operation', result = {} as T) => this.handleError(serviceName, operation, result);

  /**
   * Returns a function that handles Http operation failures.
   * This error handler lets the app continue to run as if no error occurred.
   *
   * @param serviceName = name of the data service that attempted the operation
   * @param operation - name of the operation that failed
   * @param result - optional value to return as the observable result
   */
  handleError<T>(serviceName = '', operation = 'operation', result = {} as T) {

    return (error: any): Observable<T> => {
      const isConnectionError = (error.status === 0);

      console.error(error); // log to console instead

      const message = (error instanceof ErrorEvent) ? error.message :
              isConnectionError ? 'No se puede acceder al servidor. Inténtelo más tarde.':
              error?.error?.message || error.error;

      if (message?.length > 0) {
        this.alertCtrl.create({
          header: 'Error',
          message,
          buttons: ['Aceptar']
        }).then(alert => alert.present());
      }
      // Let the app keep running by returning a safe result.
      return of(result);
    };
  }
}
