import { Component } from '@angular/core';
import { TelevisionService } from '../services/television.service';

@Component({
  selector: 'app-television',
  templateUrl: './television.component.html',
  styleUrls: ['./television.component.scss'],
})
export class TelevisionComponent {

  public onDemand = false;

  constructor(
    private televisionService: TelevisionService
  ) { }

  async ionViewDidEnter() {
    await this.televisionService.loadChannels();
  }

}
