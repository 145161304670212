import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, Router, RouterStateSnapshot, UrlTree } from '@angular/router';
import { Observable } from 'rxjs';
import { AuthService } from '../services/auth.service';

@Injectable({
  providedIn: 'root'
})
export class AuthGuard implements CanActivate {

  constructor(
    private auth: AuthService,
    private router: Router) {}


  async canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Promise<boolean> {

    //console.log('log canActivate', route, state);

    var sessionId = await this.auth.getSessionId();
    var profile = await this.auth.getProfile();

    if (sessionId != null && profile != null) {

      if (route.data?.role && profile.roleNames.indexOf(route.data.role) < 0) {
        //console.log("Falta el role", route.data?.role);
        return false;
      }

      return true;
    }

    const url: string = state.url;
    this.auth.redirectUrl = url;
    this.router.navigate(['/login']);

    return false;
  }


  canActivateChild(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    return this.canActivate(route, state);
  }
}
